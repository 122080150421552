.MainTable-Section {
  width: 80%;
}

.NavigationW {
  width: 40%;
}
.SearchBody-Drop {
  z-index: 20;
  position: relative;
}

.SavedWidth {
  width: 80%;
}

@media screen and (max-width: 900px) {
  .MainTable-Section {
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
  .disappear7 {
    display: none;
  }
  .SavedWidth {
    width: 96%;
  }
}

@media screen and (max-width: 600px) {
  .MainTable-Section {
    width: 99%;
  }
  .hideLater {
    display: none;
  }
  .NavigationW {
    width: 90%;
  }

  .bigWTrending {
    width: 100%;
  }
  .disappear6 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .disappear5 {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .fontreduceS {
    font-size: 15px;
  }
  .fontreduceN {
    font-size: 15px;
  }
  .fontreduceV {
    font-size: 13px;
  }
}