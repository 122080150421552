@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
body {
    overflow-x: hidden;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important;
  }
  *::-webkit-scrollbar {
    display: none !important; /* Safari and Chrome */
  }