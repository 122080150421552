
.ScrollBarrr {
  overflow-x: hidden;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}
*::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}
.ScrollBarrr::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

.HeroBody {
  display: flex;
  width: 100%;
}

.HeroBodyOneItem {
  width: 100%;
  min-width: 500px;
  background-color: none;
  color: black;
  box-shadow: -1px 0px 7px 0px rgba(140, 146, 156, 0.75);
}
.HeroBodyOneItem-Names {
  display: flex;
}

.HeroBodyOneItem-Names p {
  margin-top: 5px;
}
@media screen and (max-width: 1100px) {
  .HeroBodyMain {
    width: 100%;
  }

  .HeroBody {
    display: block;
  }

  .HeroBodyOne {
    width: 98% !important;
    margin: auto;
    margin-top: 30px;
    height: 100%;
  }

  .HeroBodyOneItem {
    width: 90%;
    height: 100%;
    padding: 10px;
  }
  .HeroBodyOneItem-Names {
    display: flex;
  }
  .HeroBodyOneItem-Names p {
    margin-top: 5px;
  }
}

@media screen and (max-width: 600px) {
  .HeroBodyOneItem {
    width: 98%;
    margin: auto;
    margin-left: 0px;
    min-width: 350px;
  }
}