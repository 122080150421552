.NavBar-Section {
  width: 100%;
  position: relative;
  background-color: none;
  background: none;
}

.DropdownSection {
  width: 98%;
  margin: auto;
 margin-bottom: 15px;
}

.NavBar-Body {
  width: 99%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: none;
  background: none;
}
.NavBar-Logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 60px;

}
/* .NavBar-Logo img {
  width: 120px;
  height: 120px;
} */

.NavBar-Items {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  cursor: pointer;
}

.NavBar-Items p {
  font-weight: 600;
  font-size: 17px;
  padding: 5px;
  color: rgb(27, 27, 27);
  cursor: pointer;
}

.NavBar-Items p:hover {
  font-weight: 600;
  font-size: 17px;
  padding: 5px;
  color: #0845a7;
  cursor: pointer;
}

.NavBar-Button button {
  width: 150px;
  height: 35px;
  background-color: blue;
  color: rgb(8, 8, 27);
  font-size: 14px;
  border-radius: 8px;
  border: none;
  outline: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.smallFlex {
  display: flex;
  width: 130px;
}

.HiddenUntil {
  display: none;
}

.NavBar-DropItem {
  display: flex;
  width: 96%;
  height: 60px;
  border-bottom: 1px solid black;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  margin: auto;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
  color: black;
  font-weight: 600;
}
.BigButtonNav {
  text-align: center;
  width: 95%;
  margin: auto;
  height: 45px;
  border-radius: 12px;
  padding: 5px;
  color: black;
  margin-top: 10px;
  box-shadow: 2px 5px 13px -4px rgba(30, 29, 29, 0.75);
  background-color: #222222;
}

.Nav-DropdownBTN {
  width: 100%;
  height: 100%;
  text-align: center;
}

.DropDown-NavBTNS {
  display: flex;
  width: 95%;
  margin: auto;
  justify-content: space-between;
}

.Nav-DropdownGroup {
  width: 98%;
  height: 100%;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .HiddenUntilS {
    display: none;
  }

  .HiddenUntil {
    display: block;
    margin-top: 25px;
    cursor: pointer;
  }
}

@media screen and (max-width: 400px) {
  .NavBar-Button button {
    width: 130px;
    height: 35px;
    background-color: blue;
    color: black;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    outline: none;
  }
}
