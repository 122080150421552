.MainBorder {
  width: 100%;
  padding: 0px;
}

.Search-Button {
  max-width: 19rem;
  min-width: 9rem;
}

.MainBorder-FirstFlex {
  display: flex !important;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  min-width: 400px;
  height: 100%;
}
.MainBorder-SecondFlex {
  display: flex !important;
  justify-content: space-evenly;
  flex: 1;
  align-items: center;
  min-width: 400px;
}

.LogoClass {
  font-size: 24px;
}

@media screen and (max-width: 1000px) {
  .MainBorder {
    display: block;
    height: 100%;
    padding: 5px;
    width: 95%;
    margin: auto;
  }

  .MainBorder-FirstFlex {
    display: flex !important;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    min-width: 400px;
    height: 100%;
    padding: 8px 0px;
  }

  .MainBorder-SecondFlex {
    display: flex !important;
    justify-content: none;
    flex: 1;
    width: 400px;
    margin-top: 5px;
    margin-left: -20px;
  }

  .spinnerbutton {
    margin-left: -20px;
    padding-top: 5px;
  }
}

@media screen and (max-width: 650px) {
  .MainBorder {
    display: block;
    height: 100%;
    padding: 5px;
    width: 95%;
    margin: auto;
  }

  .spinnerbutton {
    margin-left: -20px;
    padding-top: 5px;
  }

  .LogoClass {
    font-size: 16px;
  }

  .LogoClass img {
    width: 50px;
  }
}
@media screen and (max-width: 400px) {
  .MainBorder-FirstFlex {
    display: flex !important;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    min-width: 320px;
    height: 100%;
    padding: 8px 0px;
    margin-left: -20px;
  }

  .MainBorder-SecondFlex {
    display: flex !important;
    justify-content: none;
    flex: 1;
    width: 320px;
    margin-top: 5px;
    margin-left: -30px;
  }
}
